<template>
  <div class="c-third-login">
       <div class="wrap">
            <div class="qq" @click="onClick('qq')">QQ登录</div>
            <div class="other" @click="onClick('other')">其他方式登录</div>
       </div>
       <div class="wrap_bm">
        注册登录即表明同意<i  @click.stop="onPageToAgree">《网站协议》</i>和<i @click.stop="onPageToCopyright">《版权声明》</i>
       </div>
  </div>

  
</template>

<script>
import CImage from "./CImage.vue";
import { mapState } from "vuex";
export default {
    name: "c-third-login",
    components: {
        [CImage.name]: CImage,
    },
    computed: {
        ...mapState({
            os: (state) => state.os,
        }),
    },
    methods: {
        onPageToAgree() {
            this.$router.push({
                name: "agree",
            });
        },
        onPageToCopyright() {
            this.$router.push({
                name: "copyright",
            });
        },
        onClick(type) {
            if (type == "qq") {
                let _route = sessionStorage.getItem('no_login_route');
                let _baseUrl='https://www.design006.com/api/qq/index'

                if (_route == '/products') location.href = `${_baseUrl}?url2=${_route}&keywords=${this.$store.state.search.historyKeyWords}&option=${sessionStorage.getItem('option')}`;
                else location.href = `${_baseUrl}?url2=${_route}`;
            } else if (type == "other") {
                this.$emit('otherLogin')
            } 
        },
    },
};
</script>

<style>
.c-third-login{width:100%; padding: 0 15px; position: fixed; left: 0;  bottom: 0; z-index: 1;}
.c-third-login .wrap{padding: 32px 0; display: flex; justify-content: center; align-items: center;}
.c-third-login .wrap div{font-size: 14px; margin: 0 16px; height: 18px;}
.c-third-login .qq{color: #2E2E30; border-bottom: 1px solid #2E2E30;}
.c-third-login .other{color: #2E2E30; border-bottom: 1px solid #2E2E30;}
.c-third-login .wrap_bm{position: relative; left: -15px; width: 100vmin; font-size: 12px; color: #B3B3B3;text-align: center;background: #F5F5F5;padding: 8px;}
.c-third-login .wrap_bm i{font-style: normal; color: #B3B3B3;}
</style>