<template>
  <div class="register" :class="{noScollForNoLogin_safari:os.safari}">

       <div class="register-form">
           <h2>欢迎来到享设计</h2>

           <div class="form form_common" >
                <van-form @submit="onSubmit" ref="r_form">
                    <van-field v-model="mobile" autocomplete="off" type="number" maxlength="11" name="mobile" placeholder="手机号码" :rules="[{ required: true, message: '请输入手机号码' },{pattern,message: '手机号码格式错误'}]"/>
                    <van-field v-model="password" autocomplete="off" type="password" name="password" placeholder="设置密码" :rules="[{ required: true, message: '请输入登录密码' }]"/>
                </van-form>

                 <c-button bClass="btn_register" @btnClick="onRegister">注册</c-button>

                 <div class="register-other">
                    <p class="cue">已有帐号？</p><p class="btn_register2" @click="onPageToLogin">马上登录</p>
                </div>
           </div>
       </div>
       <c-third-login @otherLogin="onOtherLogin"></c-third-login>

       <van-overlay :show="showDialog" @click="showDialog = false">
        <div class="common-dialog" @click.stop >
            <div class="icon_close" @click="showDialog = false">
                <c-image iUrl="icon-close_2" iWidth="14" iHeight="14"></c-image>
            </div>
            <c-image iUrl="icon-tipoff" iClass="icon" iWidth="36" iHeight="36" ></c-image>
            <h1>微信/微博登录</h1>
            <p class="third_cue">由于第三方限制<br>微信/微博无法直接登录享设计移动端<br>请至PC官网“我的信息”页面<br>绑定邮箱账号和登录密码</p>
            <div class="btn" @click="onPageToBind">已绑定, 马上登录</div>
        </div>
    </van-overlay>   
  </div>
</template>

<script>
import { Field, Form,Overlay } from "vant";
import CButton from "../../components/form/CButton.vue";
import CImage from "../../components/CImage.vue";
import CThirdLogin from "../../components/CThirdLogin.vue";
import { mapState, mapActions } from "vuex";
export default {
    components: {
        CThirdLogin,
        [CButton.name]: CButton,
        [CImage.name]: CImage,
        [Field.name]: Field,
        [Form.name]: Form,
        [Overlay.name]:Overlay
    },
    data() {
        return {
            pattern: /^0?1[3|4|5|6|7|8|9][0-9]\d{8}$/,
            mobile: "",
            password: "",
            showDialog:false
        };
    },
    computed: {
        ...mapState({
            fromRouteName: (state) => state.fromRouteName,
             os: (state) => state.os,
        }),
    },
    methods: {
        ...mapActions({
            requestRegister: "user/requestRegister",
        }),
        onPageToBind(){
            this.showDialog=false;
            this.$router.push({
                name:'bind'
            })
        },
        onOtherLogin(){
            this.showDialog=true;
        },
        onPageToLogin() {
            if (this.fromRouteName == "login") this.$router.go(-1);
            else this.$router.push({ name: "login" });
        },
        onSubmit(e) {
            this.requestRegister(Object.assign(e, { step: 1 })).then(() => {
                this.$router.push({
                    name: "verify",
                    params: e,
                });
            });
        },
        onRegister() {
            this.$refs.r_form.submit();
        },
    },
    created() {
        let _uuid = this.$cookies.isKey("user_id");
        if (_uuid) {
            this.$router.replace({
                name: "home",
            });
        }
    },
};
</script>

<style>
.register{padding-top: 47px; height: 100vh; background: #fff;}
.register-form{padding:20px 20px 0;}
.register-form h2{font-size: 20px;color: #2E2E30; text-align: center;font-weight: normal;}
.register-form .form{margin-top: 20px;}
.register-form .btn_register{margin-top: 32px;}
.register-other{display: flex; justify-content: center; align-items: center; margin-top: 20px;}
.register-other p{font-size: 14px; margin: 0 4px; color: #2E2E30;}
.register-other .cue{color: #B3B3B3; margin-right:0;}
.register-other .btn_register2{color: #F85659; margin-left:0; height: 17px; border-bottom: 1px solid #F85659;}
.register .third_cue{line-height: 21px;}
</style>